import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import AddSchool from 'src/components/add-school/add-school'
import { RouteComponentProps } from '@reach/router'
import { Router } from '@reach/router'

const NewSchoolPage = () => {
  usePrivateRoute()

  return (
    <Router basepath="/schools">
      <NewSchoolRoute path="/add" />
    </Router>
  )
}

const NewSchoolRoute: React.FC<RouteComponentProps> = props => {
  return (
    <Layout>
      <SEO title="Add School" />
      <AddSchool {...props} />
    </Layout>
  )
}

export default NewSchoolPage
